<!-- 添加修改角色信息 -->
<template>
    <a-modal
        :title="title"
        :visible.sync="visible"
        :width="600"
        @cancel="close()"
        @ok="postData()">
        <a-spin :spinning="loading">
            <a-form ref="form" :form="form">
                <a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入角色名称" v-decorator="['roleName', {rules: ['roleName',{required: true, min:1, message: '请输入角色名称'}],initialValue: formData.roleName, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="角色描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入角色描述，最多50个字符" v-decorator="['roleDesc', {rules: ['roleDesc',{required: true, min:1, max:50, message: '请输入角色描述,最多50个字符'}],initialValue: formData.roleDesc, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="选择菜单" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-spin :spinning="menuLoading">
                        <a-tree
                            ref="tree"
                            v-if="menuData && menuData.length > 0"
                            :tree-data="menuData"
                            v-model="checkedKeys"
                            :selected-keys="checkedKeys"
                            :defaultExpandedKeys="checkedKeys"
                            :checkStrictly="false"
                            :replaceFields="replaceFields"
                            checkable
                            style="width:500px;"
                            @check="nodeCheck">
                            <template slot="custom" slot-scope="node">
                                <span class="node-title">
                                    <a-icon type="folder" v-if="node.dataRef.type === 1" theme="twoTone" two-tone-color="red"/>
                                    <a-icon type="file" v-if="node.dataRef.type === 2" theme="twoTone" two-tone-color="blue"/>
                                    <a-icon type="down-circle" v-if="node.dataRef.type === 3" theme="twoTone" two-tone-color="green"/>
                                    {{ node.name }}
                                </span>
                            </template>
                        </a-tree>
                    </a-spin>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>
<script>
export default {
    data () {
        return {
            title: '修改角色',
            visible: false,
            loading: false,
            menuLoading: false,
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            replaceFields: { children:'children',title:'name', key:'id' },
            menuData: [],
            form: this.$form.createForm(this),
            checkedKeys:[],
            formData: {
                id: '',
                roleName: '',
                roleDesc: '',
                checkMenus: []
            }
        }
    },
    methods: {
        show (role) {
            this.visible = true
            if (role != null) {
                this.title = "修改角色"
                this.formData.id = role.id
                this.formData.roleName = role.roleName
                this.formData.roleDesc = role.roleDesc
            }
            this.findRoleMenuTree()
        },
        findRoleMenuTree () {
            this.menuLoading = true
            this.$api.menu.findRoleMenuTree(this.formData.id).then(res => {
                this.menuLoading = false
                if (res.status === 0) {
                    this.menuData = res.data.managerMenuTree
                    this.checkedKeys = res.data.checkedMenu
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.menuLoading = false
                this.$message.error('网络繁忙')
            })
        },
        close () {
            this.loading = false
            this.visible = false
            this.form.resetFields()
            this.checkedKeys = []
            this.menuData = []
            this.formData = {
                id: '',
                roleName: '',
                roleDesc: '',
                checkMenus: []
            }
        },
        nodeCheck (checkedKeys, e) {
            let checkMenus = []
            let checkKeys = []
            checkedKeys.forEach(key => {
                checkMenus.push(key)
            })
            let halfCheckedKeys = e.halfCheckedKeys
            halfCheckedKeys.forEach(key => {
                checkMenus.push(key)
            })
            this.formData.checkMenus = Array.from(new Set(checkMenus))
            // =========
            this.checkKeys = checkedKeys
        },
        postData () {
            console.log('tree', this.$refs.tree)
            if (this.formData.checkMenus.length === 0) {
                this.$message.warning('请选择菜单')
                return
            }
            this.form.validateFields((error, values) => {
                if (!error) {
                    this.formData.roleName = values['roleName']
                    this.formData.roleDesc = values['roleDesc']
                    this.updateRole()
                }
            })
        },
        updateRole () {
            console.log('this.formData', this.formData)
            this.loading = true
            this.$api.role.updateRole(this.formData).then(res => {
                if (res.status === 0) {
                    this.$message.success('修改成功')
                    this.$emit('success')
                    this.close()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        findParentIdById (menus, menuId) {
            let parentId = null
            menus.forEach(menu => {
                let id = menu.id
                if (id === menuId) {
                    parentId = id
                    return parentId
                } else {
                    let children = menu.children
                    if (children != null && children.length > 0) {
                        this.findParentIdById(children, menuId)
                    }
                }
            })
            return parentId
        }
    }
}
</script>
